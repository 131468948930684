import React, { Component } from 'react';
import Table from '../../components/Table';
import { Link } from 'react-router-dom';
import Nav from '../../components/Nav';
import Modal from '../../components/modal';
import Form from '../../components/form';
import { CheckCircle, XCircle } from 'react-feather';
import moment from 'moment';

class Stk extends Component {
  state = {
    tableData: { data: [] },
    response: { data: [] },
    tableError: false,
    query: {},
    filter: {},
    table_loading: false,
  };
  timeout = null;



  render() {

      return (
      <div className=''>
        <Nav
    name='MpesaSTK'
    />
        <div className='mt-3 table-card border-0 card shado mx-3 shadow'>
          <div className='p-4'>
            <Table
              search={['shortCode', 'amount',
                  'msisdn', 'request_id',
                  'accountRef', 'mpesa_type', 'MerchantRequestID', 'CheckoutRequestID', 'ResponseCode', 'ResponseDescription']}
              // sort="created_at"
              // sortDirection={-1}
              data={this.state.tableData}
              fetch={(params) => {
                this.setState({ query: params });
                setTimeout(() => {
                  this.fetch();
                }, 0);
              }}
              loading={this.state.table_loading}
              fetchError={this.state.tableError}
            />
          </div>
        </div>

        <Modal visible={this.state.modalVisible}>
          <h5 className='font-weight-bold'>Create IP Address</h5>

          <hr />

          <div className=''>
            <Form
              inputs={[
                // {
                //   label: 'IP Address',
                //   name: 'ip_address',
                //   type: 'text',
                // },
                // {
                //   label: 'Country',
                //   name: 'country',
                //   type: 'text',
                // },
                // {
                //   label: 'state',
                //   name: 'state',
                //   type: 'select',
                //   options: [
                //     {
                //       name: 'Whitelisted',
                //       value: 'WHITELISTED',
                //     },
                //     {
                //       name: 'Blacklisted',
                //       value: 'BLACKLISTED',
                //     },
                //   ],
                // },
                // {
                //   label: 'Description',
                //   name: 'description',
                //   type: 'textarea',
                // },
              ]}
              submit={(data) => {
                this.setState({ modalVisible: false });
                // this.createIP(data);
              }}
            />
          </div>
        </Modal>
      </div>
    );
  }

  fetch = () => {
    this.setState({ table_loading: true });

    window.app
      .service('mpesa/stk')
      .find({
        query: {
          ...this.state.query,
            request_id: this.props.paymentRequest?.id,
        },
      })
      .then((response) => {
          console.log(response)
        response.data = response.data.map((d) => {
          return {

            id: d.id,
              PaymentRequest: (
                  <Link
                      className='link'
                      to={`/management//payment_requests/${d.request_id}/details`}>
                      Open_{d.accountRef}
                  </Link>
              ),
              shortCode: (<code>{d.shortCode}</code>),
              mpesa_type: d.mpesa_type,
              MerchantRequestID: d.MerchantRequestID,
              CheckoutRequestID: d.CheckoutRequestID,
              ResponseCode: d.ResponseCode,
              ResponseDescription: d.ResponseDescription,
              ResultCode: d.ResultCode,
              ResultDescription: d.ResultDescription,

              date_created: moment(d.date_created).format('YYYY-MM-DD hh:mm:ss'),
          };
        });

        this.setState({ tableData: response, table_loading: false });
      })
      .catch((err) => {
        this.setState({ table_loading: false });
        console.error(err);
      });
  };
  //
  // createIP = (data) => {
  //   window.alert2.show({
  //     loader: true,
  //     title: 'Updating IP ...',
  //     buttons: false,
  //   });
  //
  //   window.app
  //     .service('covers')
  //     .create(data)
  //     .then((response) => {
  //       this.fetchCovers();
  //
  //       window.alert2.show({
  //         loader: false,
  //         icon: <CheckCircle size={60}></CheckCircle>,
  //         title: 'Updated Successfully',
  //         buttons: true,
  //         onSubmit: () => {},
  //       });
  //       this.setState(response);
  //     })
  //     .catch((err) => {
  //       window.alert2.show({
  //         loader: false,
  //         icon: <XCircle size={60} className='text-danger'></XCircle>,
  //         title: 'Error',
  //         message: err.message,
  //         buttons: true,
  //       });
  //     });
  // };
  //
  // removeItem = (id) => {
  //   window.alert2.show({
  //     loader: true,
  //     title: 'Removing Item ...',
  //     buttons: false,
  //   });
  //
  //   window.app
  //     .service('covers')
  //     .remove(id)
  //     .then((response) => {
  //       this.fetchCovers();
  //       window.alert2.hide();
  //       window.alert2.notify({
  //         title: 'Removed Successfully',
  //       });
  //       this.setState(response);
  //     })
  //     .catch((err) => {
  //       window.alert2.show({
  //         loader: false,
  //         icon: <XCircle size={60} className='text-danger'></XCircle>,
  //         title: 'Error',
  //         message: err.message,
  //         buttons: true,
  //       });
  //     });
  // };
}

export default Stk;
