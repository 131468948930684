import React, { Component } from 'react';
import Table from '../../components/Table';
import { Link } from 'react-router-dom';
import Nav from '../../components/Nav';
import moment from 'moment';
import Filter from '../../components/filter';
import Access from '../../components/accessManager';

class Results extends Component {
  state = {
    tableData: { data: [] },
    response: { data: [] },
    tableError: false,
    query: {},
    filter: {},
    table_loading: false,
  };
  timeout = null;
  render() {
    return (
      <div className=''>
        <Nav
    name='Payment Requests'
    buttons={[
      {
        text: 'Add a Customer',
        link: '/payment_requests/create',
        permission: 'PAYMENT_REQUEST_CREATE',
      },
    ]}/>
        <div className='mt-3 table-card border-0 card shado mx-3 shadow'>
          <div className='p-4'>
            <Filter
                filter={[
                  {
                    label: 'Mpesa/Airtel',
                    name: '$and[4][interface][$like]',
                    options: [
                      {
                        label: 'All',
                        value: 0,
                        default: true,
                      },
                      {
                        label: 'MPESA',
                        value: "MPESA",
                      },

                      {
                        label: 'AIRTEL_MONEY',
                        value: "AIRTEL_MONEY",
                      },

                    ]
                  },{
                    label: 'B2C/C2B',
                    name: '$and[5][level][$like]',
                    options: [
                      {
                        label: 'All',
                        value: 0,
                        default: true,
                      },
                      {
                        label: 'B2C',
                        value: "2",
                      },

                      {
                        label: 'C2B',
                        value: "1",
                      },

                    ]
                  },{
                    label: 'Status',
                    name: '$and[5][response_status][$like]',
                    options: [
                      {
                        label: 'All',
                        value: 0,
                        default: true,
                      },
                      {
                        label: 'Pending',
                        value: "0",
                      },

                      {
                        label: 'FULFILLED',
                        value: "2",
                      },
                      {
                        label: 'FULFILLED_FOREIGN',
                        value: "55",
                      },

                    ]
                  },
                ]}

                onChange={(filter) => {
                  console.log(filter)
                  this.setState({ filter });
                  setTimeout(() => {
                    this.fetchData();
                  }, 0);
                }}
            />
            <Table
              search={['ack_payment_reference','interface','msisdn','reference','response_txt',]}
              // sort="id"
              // sortDirection={1}
              data={this.state.tableData}
              refreshAllowed={true}
              fetch={(params) => {
                this.setState({ query: params });
                setTimeout(() => {
                  this.fetchData();
                }, 0);
              }}
              loading={this.state.table_loading}
              fetchError={this.state.tableError}
              dateRange={['startdate', 'enddate']}
              dateRangeLabel='Date Range'
              csv={() => ({
                name: 'customers',
                query: { ...this.state.query, ...this.state.filter },
              })}
            />
          </div>
        </div>
      </div>
    );
  }

  fetchData = () => {
    let { query, filter } = this.state;
    let q = { ...query, ...filter };

    this.setState({ table_loading: true });

    let status = {
      0: <span className='badge badge-danger'>Pending</span>,
      1: <span className='badge badge-warning'>On Process</span>,
      2: <span className='badge badge-success'>FULFILLED</span>,
      55: <span className='badge badge-success'>FULFILLED</span>,
    };
    console.log('Starting..')

    window.app
      .service('payments/requests')
      .find({
        query: {
          ...q,
        },
      })
      .then((response) => {
        response.data = response.data.map((d) => {
          console.log('Payments')
          console.log(d)
          return {
            id: d.id,
            description: d.description,
            interface: d.interface,
            amount: d.amount,
            msisdn: d.msisdn,
            reference: d.reference,
            ack_ref: (<code>{d.ack_payment_reference}</code>),
            fulfilment_message: <b>{JSON.parse(d.response_txt)?.message}</b>,
            // level: d.level,
            date_created: moment(d.date_created).format('YYYY-MM-DD hh:mm:ss'),
            status: status[d.response_status],
            action: (
              <Access permission='PAYMENT_REQUEST_READ'>
                <Link
                  className='btn btn-sm btn-round btn-outline-primary px-3'
                  to={`/management/payment_requests/${d.id}/details`}>
                  See More
                </Link>
              </Access>
            ),
          };
        });

        this.setState({ tableData: response, table_loading: false });
      })
      .catch((err) => {
        this.setState({ table_loading: false });
        console.error(err);
      });
  };
}

export default Results;
