import React, { Component } from 'react';
import moment from 'moment';
import { Check, X } from 'react-feather';
import Access from './accessManager';
import {
  DateRangePicker,
  SingleDatePicker,
  DayPickerRangeController,
} from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import 'react-dates/initialize';

class Filter extends Component {
  state = {
    ranges: {
      startDate: null,
      endDate: null,
    },
    query: {},
  };

  render() {
    return (
      <>
        <div className='d-flex flex-row justify-content-between mb-3 position-relative'>
          <div className='d-flex flex-row'>
            {this.props.filter &&
              this.props.filter.map((d, i) => (
                <div className='mx-2 flex-column' key={i}>
                  <small className='mb-2 font-weight-bold'>{d.label}</small>
                  <select
                    name=''
                    id=''
                    className='form-control py-1 filter-option'
                    onChange={(e) => {
                      let { query } = this.state;

                      let option = d.options[e.target.value];

                      if (option.default) {
                        console.log(option.default);
                        delete query[d.name];
                      } else {
                        query[d.name] = option.value;
                      }

                      this.setState({ query });
                      setTimeout(() => {
                        this.updateFilter();
                      }, 0);
                    }}
                  >
                    {d.options.map((d1, i1) => (
                      <option value={i1} key={i1}>
                        {d1.label}
                      </option>
                    ))}
                  </select>
                </div>
              ))}
          </div>

          {this.props.dateRange && (
            <div>
              <div htmlFor=''>
                <small className='font-weight-bold mb-2'>
                  {this.props.dateRangeLabel || 'Date Range'}
                </small>
              </div>
              <DateRangePicker
                startDate={this.state.ranges.startDate} // momentPropTypes.momentObj or null,
                startDateId='start_date' // PropTypes.string.isRequired,
                endDate={this.state.ranges.endDate} // momentPropTypes.momentObj or null,
                endDateId='end_date' // PropTypes.string.isRequired,
                onDatesChange={({ startDate, endDate }) => {
                  let { query, ranges } = this.state;

                  ranges.startDate = startDate;

                  query[this.props.dateRange[0]] = startDate.format(
                    'YYYY-MM-DD'
                  );
                  if (endDate) {
                    query[this.props.dateRange[1]] = endDate.format(
                      'YYYY-MM-DD'
                    );
                    ranges.endDate = endDate;
                  }

                  this.setState({ ranges, query });

                  setTimeout(() => {
                    this.updateFilter();
                  }, 0);
                }}
                focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                onFocusChange={(focusedInput) =>
                  this.setState({ focusedInput })
                } // PropTypes.func.isRequired,
                isOutsideRange={() => false}
              />
            </div>
          )}
        </div>
      </>
    );
  }

  componentDidMount() {
    // this.updateFilter();
  }

  updateFilter = () => {
    if (this.props.onChange) this.props.onChange(this.state.query);
  };
}

export default Filter;
