import React, { Component } from 'react';
import Table from '../../components/Table';
import { Link } from 'react-router-dom';
import Nav from '../../components/Nav';
import Modal from '../../components/modal';
import Form from '../../components/form';
import { CheckCircle, XCircle } from 'react-feather';
import moment from 'moment';

class C2BResults extends Component {
  state = {
    tableData: { data: [] },
    response: { data: [] },
    tableError: false,
    query: {},
    filter: {},
    table_loading: false,
  };
  timeout = null;


 
  render() {

      return (
      <div className=''>
        <Nav
    name='MpesaB2C'
    buttons={[
        // {
        //     text: 'C2B Stk',
        //     onClick: () => {
        //         this.setState({modalVisible: true});
        //     },
        // },
    ]}/>
        <div className='mt-3 table-card border-0 card shado mx-3 shadow'>
          <div className='p-4'>
            <Table
              search={['msisdn','transaction_id', 'mpesa_resp_desc', 'orig_trx_id', 'original_conversation_id', 'orig_trx_id', 'result_code', 'mpesa_receipt_number']}
              // sort="created_at"
              // sortDirection={-1}
              data={this.state.tableData}
              fetch={(params) => {
                this.setState({ query: params });
                setTimeout(() => {
                  this.fetch();
                }, 0);
              }}
              loading={this.state.table_loading}
              fetchError={this.state.tableError}
            />
          </div>
        </div>

        <Modal visible={this.state.modalVisible}>
          <h5 className='font-weight-bold'>Create IP Address</h5>

          <hr />

          <div className=''>
            <Form
              inputs={[
                // {
                //   label: 'IP Address',
                //   name: 'ip_address',
                //   type: 'text',
                // },
                // {
                //   label: 'Country',
                //   name: 'country',
                //   type: 'text',
                // },
                // {
                //   label: 'state',
                //   name: 'state',
                //   type: 'select',
                //   options: [
                //     {
                //       name: 'Whitelisted',
                //       value: 'WHITELISTED',
                //     },
                //     {
                //       name: 'Blacklisted',
                //       value: 'BLACKLISTED',
                //     },
                //   ],
                // },
                // {
                //   label: 'Description',
                //   name: 'description',
                //   type: 'textarea',
                // },
              ]}
              submit={(data) => {
                this.setState({ modalVisible: false });
                // this.createIP(data);
              }}
            />
          </div>
        </Modal>
      </div>
    );
  }

  fetch = () => {
    this.setState({ table_loading: true });

    window.app
      .service('mpesa/b2c_results')
      .find({
        query: {
          ...this.state.query,
          transaction_id: this.props.paymentRequest?.id,
        },
      })
      .then((response) => {
          console.log(response)
        response.data = response.data.map((d) => {
          return {

            id: d.id,
            transaction_id: (
                  <Link
                      className='link'
                      to={`/management/payment_requests/${d.transaction_id}/details`}>
                      {d.transaction_id}
                  </Link>
              ),
            msisdn: d.msisdn,
            amount: d.amount,
            mpesa_receipt_number: (
                <Link
                    className='link'
                    to={`/management/payment_requests/${d.mpesa_receipt_number}/details`}>
                  {d.mpesa_receipt_number}
                </Link>
            ),
            original_conversation_id: d.original_conversation_id,
            orig_trx_id: d.orig_trx_id,
            mpesa_resp_desc: d.mpesa_resp_desc,
            reversal_status: d.reversal_status,
            result_type: d.result_type,
            result_code: d.result_code,
            result_desc: d.result_desc,
            transaction_receipt: d.transaction_receipt,
            receiver_party_public_name: d.receiver_party_public_name,
            available_funds: d.available_funds,
            completed_date: d.completed_date,
            date_created: moment(d.created_at).format('YYYY-MM-DD hh:mm:ss'),
          };
        });

        this.setState({ tableData: response, table_loading: false });
      })
      .catch((err) => {
        this.setState({ table_loading: false });
        console.error(err);
      });
  };
  //
  // createIP = (data) => {
  //   window.alert2.show({
  //     loader: true,
  //     title: 'Updating IP ...',
  //     buttons: false,
  //   });
  //
  //   window.app
  //     .service('covers')
  //     .create(data)
  //     .then((response) => {
  //       this.fetchCovers();
  //
  //       window.alert2.show({
  //         loader: false,
  //         icon: <CheckCircle size={60}></CheckCircle>,
  //         title: 'Updated Successfully',
  //         buttons: true,
  //         onSubmit: () => {},
  //       });
  //       this.setState(response);
  //     })
  //     .catch((err) => {
  //       window.alert2.show({
  //         loader: false,
  //         icon: <XCircle size={60} className='text-danger'></XCircle>,
  //         title: 'Error',
  //         message: err.message,
  //         buttons: true,
  //       });
  //     });
  // };
  //
  // removeItem = (id) => {
  //   window.alert2.show({
  //     loader: true,
  //     title: 'Removing Item ...',
  //     buttons: false,
  //   });
  //
  //   window.app
  //     .service('covers')
  //     .remove(id)
  //     .then((response) => {
  //       this.fetchCovers();
  //       window.alert2.hide();
  //       window.alert2.notify({
  //         title: 'Removed Successfully',
  //       });
  //       this.setState(response);
  //     })
  //     .catch((err) => {
  //       window.alert2.show({
  //         loader: false,
  //         icon: <XCircle size={60} className='text-danger'></XCircle>,
  //         title: 'Error',
  //         message: err.message,
  //         buttons: true,
  //       });
  //     });
  // };
}

export default C2BResults;
