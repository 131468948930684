import React, { Component } from 'react';
import { Route, withRouter } from 'react-router-dom';

import './globals';

// pages
import Login from './pages/login';
import Home from './pages/home';
import Menu from './components/Menu';
import Alert from './components/alert';
import {default as PaymentResults} from './pages/payment_requests/Results';
import {default as PaymentResultDetails} from './pages/payment_requests/ResultDetails';
import {default as MpesaSTKDetails} from './pages/mpesa/stk';
import {default as MpesaC2BResults} from './pages/mpesa/c2b_results';
import {default as MpesaB2CResults} from './pages/mpesa/b2c_results';

import {default as AirtelSTKDetails} from './pages/airtel/stk';
import {default as AirtelC2BResults} from './pages/airtel/c2b_results';
import {default as AirtelB2CResults} from './pages/airtel/b2c_results';

import {default as Admins} from './pages/admin/admins';

import AuditTrail from './pages/audit/audit';
import AuditData from './pages/audit/auditData';

import Roles from './pages/admin/roles';
import IPAdress from './pages/ip_address';
import AdminCreate from './pages/admin/adminCreate';
import MakerChecker from './pages/admin/makerChecker';
import MakerCheckerLogs from './pages/admin/makerCheckerLogs';
import Dashboards from './pages/dashboards';
import Access from './components/accessManager';

class App extends Component {
  state = { blur: false, authenticated: false };
  render() {
    return (
      <>
        <Alert
    onRef={(ref) => {
      window.alert2 = ref;
    }}
    toggle={(blur) => {
      this.setState({blur});
    }}/>
        <div
          className={`page-blur pa
        ge ${this.state.blur && 'blur-alert'}`}>
          <Access permission='all'>
            <Route path='/login' exact component={Login}/>
          </Access>

          {this.state.authenticated && (
            <Portal hist={this.props.history}/>
          )}
        </div>
        <div
          className={`preloader-cover ${
            this.state.loaded && 'hide-preloader'
          }`}>
          <div className='alert-loader px-3 my-3 d-flex flex-row justify-content-center'>
            <div className='nest-parent'>
              <div id='nest1'/>
            </div>
          </div>
        </div>
      </>
    );
  }

  componentDidMount() {
    window.app
      .reAuthenticate()
      .then((response) => {
        // console.log(response);
        this.fetchDefaults();

        let path = window.location.pathname;

        console.log('path before ', path);

        if (path.includes('login') || path.includes('get-started'))
          return false;
        console.log('path after ', path);
      })
      .catch((error) => {
        console.log(error);
        this.setState({ loaded: true, authenticated: false });
        let p = window.location.pathname;
        if (!['/password-change', '/password-forgot'].includes(p))
          this.props.history.push('/login');
      });
  }

  get = (model, callback, filter) => {
    window.app
      .service(model)
      .find({
        query: {
          $limit: 250,
          $skip: 0,
          ...(filter ? filter : {}),
        },
      })
      .then((response) => {
        let data = [];
        response.data.map((d) => {
          data[d.id] = d;
        });
        callback(data);
        this.verifyFetch();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  fetchDefaults = () => {
    this.get(
      `admin-group-roles`,
      (response) => {
        let roles = {};
        response.map((d) => {
          // console.log(d)
          if (!roles[d.admin_role.name]) roles[d.admin_role.name] = [];
          roles[d.admin_role.name].push(d.admin_group.id);
        });

        window.roles = roles;
      },
      {
        group_id: window.user && window.user.admin.group_id,
      }
    );

    this.get(`admin-groups`, (response) => {
      console.log(response);
      window.groups = response;
    });

    // let roles = {};
    // roles = { CUSTOMER_READ: [1], CUSTOMER_CREATE: [1] };
    // window.roles = roles;
    this.verifyFetch();
  };

  verifyFetch = () => {
    if (
      window.roles &&
      // window.global_status &&
      // window.orderTypes &&
      window.groups
      // window.banks
    ) {
      let loaded = false;
      if (localStorage.user) loaded = true;
      this.setState({ authenticated: true, loaded });
    }
  };
}

class Portal extends Component {
  state = {};
  history = this.props.hist;
  render() {
    return (
      <>
        <div id='wrapper' className='pt-5 mt-md-0 pt-md-0 mt-2'>
          <Menu/>
          <div id='content-wrapper' className='d-flex flex-column bg-light'>
            <div className='d-flex flex-fill flex-column'>
              <Access permission='all'>
                <Route path='/' exact component={Home} />
              </Access>

              <Access permission='all'>
                <Route path='/home' exact component={Home} />
              </Access>
              <Access permission='ADMIN_READ'>
                <Route path='/administration/admins' exact component={Admins} />
              </Access>

              <Access permission='ROLES_READ'>
                <Route path='/administration/roles' exact component={Roles} />
              </Access>


              <Access permission='AUDIT_READ'>
                <Route path='/administration/audit' exact component={AuditTrail} />
              </Access>

              <Access permission='AUDIT_READ'>
                <Route path='/administration/audit-data' exact component={AuditData} />
              </Access>


              <Access permission='IP_ADDRESS_READ'>
                <Route path='/administration/ip' exact component={IPAdress} />
              </Access>



              <Access permission='ADMIN_CREATE'>
                <Route path='/administration/admin-create' exact component={AdminCreate} />
              </Access>

              <Access permission='MAKER_CHECKER_READ'>
                <Route path='/administration/maker-checker' exact component={MakerChecker} />
              </Access>

              <Access permission='all'>
                <Route path='/dashboards/:type' exact component={Dashboards} />
              </Access>
              <Access permission='MAKER_CHECKER_CONFIRM'>
                <Route
                    path='/administration/maker-checker/:id/:status'
                    exact
                    component={MakerCheckerLogs}
                />
              </Access>


              {/*------------------------------------------------------------*/}

              <Access permission='PAYMENT_REQUEST_READ'>
                <Route path='/management/payment_requests' exact component={PaymentResults} />
              </Access>


              <Access permission='PAYMENT_REQUEST_READ'>
                <Route path='/management/payment_requests/:id/:tab' component={PaymentResultDetails} />
              </Access>

              <Access permission='MPESA_MONEY_ALL'>
                <Route path='/management/mpesa/home' component={MpesaSTKDetails} />
              </Access>

              <Access permission='MPESA_STK_READ'>
                <Route path='/management/mpesa/stk' component={MpesaSTKDetails} />
              </Access>

              <Access permission='MPESA_C2B_READ'>
                <Route path='/management/mpesa/c2b_results' component={MpesaC2BResults} />
              </Access>

              <Access permission='MPESA_B2C_READ'>
                <Route path='/management/mpesa/b2c_results' component={MpesaB2CResults} />
              </Access>


              <Access permission='AIRTEL_MONEY_ALL'>
                <Route path='/management/airtel/home' component={AirtelSTKDetails} />
              </Access>

              <Access permission='AIRTEL_STK_READ'>
                <Route path='/management/airtel/stk' component={AirtelSTKDetails} />
              </Access>

              <Access permission='AIRTEL_C2B_READ'>
                <Route path='/management/airtel/c2b_results' component={AirtelC2BResults} />
              </Access>

              <Access permission='AIRTEL_B2C_READ'>
                <Route path='/management/airtel/b2c_results' component={AirtelB2CResults} />
              </Access>



            </div>
          </div>
        </div>
      </>
    );
  }

  componentDidMount() {
    if (!localStorage.user) this.props.history.push('/login');
  }
}

export default withRouter(App);
