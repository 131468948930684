import React, { Component } from 'react';
import Table from '../../components/Table';
import { Link } from 'react-router-dom';
import Nav from '../../components/Nav';
import Modal from '../../components/modal';
import ReactJson from 'react-json-view';
import moment from 'moment';

class AuditTrail extends Component {
  state = {
    tableData: { data: [] },
    response: { data: [] },
    tableError: false,
    query: {},
    filter: {},
    table_loading: false,
  };
  timeout = null;
  render() {
    return (
      <div className=''>
        <Nav name='Audit Trail'></Nav>
        <div className='mt-3 table-card border-0 card shado mx-3 shadow'>
          <div className='p-4'>
            <Table
              // search={['firstname', 'lastname']}
              // sort="created_at"
              // sortDirection={-1}
              data={this.state.tableData}
              fetch={(params) => {
                this.setState({ query: params });
                setTimeout(() => {
                  this.fetchAuditTrail();
                }, 0);
              }}
              loading={this.state.table_loading}
              fetchError={this.state.tableError}
            />
          </div>
        </div>
        {this.state.json && (
          <Modal
            visible={true}
            close={() => {
              this.setState({ json: null });
            }}>
            <h5 className='font-weight-bold'>Data Preview</h5>
            <hr />
            <ReactJson
              displayDataTypes={false}
              displayObjectSize={false}
              src={this.state.json}
            />
          </Modal>
        )}
      </div>
    );
  }

  fetchAuditTrail = () => {
    this.setState({ table_loading: true });

    window.app
      .service('portal-activity-log')
      .find({
        query: {
          ...this.state.query,
        },
      })
      .then((response) => {
        response.data = response.data.map((d) => {
          return {
            // id: d.id,
            admin: d.adminObj.name,
            activity_type: <code>{d.activity_type}</code>,
            description: d.description,
            ip_address: d.ip_address,
            data: d.data && (
              <button
                className='btn btn-sm btn-outline-success font-weight-bold'
                onClick={() => this.setState({ json: JSON.parse(d.data) })}>
                view
              </button>
            ),
            created: moment(d.created).format('Do MMMM YYYY hh:mm a'),
          };
        });

        this.setState({ tableData: response, table_loading: false });
      })
      .catch((err) => {
        this.setState({ table_loading: false });
        console.error(err);
      });
  };
}

export default AuditTrail;
