import React, { Component } from 'react';
import Nav from '../components/Nav';

class Dashboards extends Component {
  state = {
    panels: {
      tp10: {
        name: 'Top 10',
        graphs: [
          {
            name: 'top',
            url:
              window.grafanaServer +
              '/d-solo/u82JEYKGz/tops?orgId=1&refresh=5s&panelId=2',
          },

          {
            name: 'Deposits',
            url:
              window.grafanaServer +
              '/d-solo/u82JEYKGz/tops?orgId=1&refresh=5s&panelId=3',
          }, {
            name: 'Top Withdrawals',
            url:
              window.grafanaServer +
              '/d-solo/u82JEYKGz/tops?orgId=1&refresh=5s&panelId=6',
          }, {
            name: 'Latest Withdrawals',
            url:
              window.grafanaServer +
              '/d-solo/u82JEYKGz/tops?orgId=1&refresh=5s&panelId=4',
          }, {
            name: 'Top 10 Transactions',
            url:
              window.grafanaServer +
              '/d-solo/u82JEYKGz/tops?orgId=1&refresh=5s&panelId=7',
          }, {
            name: 'Latest Transactions',
            url:
              window.grafanaServer +
              '/d-solo/u82JEYKGz/tops?orgId=1&refresh=5s&panelId=5',
          }
        ],
      },

      activity: {
        name: 'Activity',
        graphs: [
          
          {
            name: 'Withdrawals',
            size:"col-md-12 my-3",
            height:"250",
            url:
              window.grafanaServer +
              '/d-solo/adyxOYFGz/test-metrics?orgId=1&refresh=5s&panelId=19',
          },
          {
            name: 'Withdrawals',
            size:"col-md-12",
            height:"200",
            url:
              window.grafanaServer +
              '/d-solo/adyxOYFGz/test-metrics?orgId=1&refresh=5s&panelId=20',
          },

          {
            name: 'Deposit heatmaps',
            url:
              window.grafanaServer +
              '/d-solo/adyxOYFGz/test-metrics?orgId=1&refresh=5s&panelId=9',
          },
          {
            name: 'yy',
            url:
              window.grafanaServer +
              '/d-solo/adyxOYFGz/test-metrics?orgId=1&refresh=5s&panelId=12',
          },
        ],
      },

      aml: {
        name: 'AML Analytics',
        graphs: [
          // {
          //   name: 'yy',
          //   url:
          //     window.grafanaServer +
          //     '/d-solo/adyxOYFGz/test-metrics?orgId=1&refresh=5s&panelId=12',
          // },

          {
            name: 'yy',
            url:
              window.grafanaServer +
              '/d-solo/adyxOYFGz/test-metrics?orgId=1&refresh=5s&panelId=16',
          },
          {
            name: 'yy',
            url:
              window.grafanaServer +
              '/d-solo/adyxOYFGz/test-metrics?orgId=1&refresh=5s&panelId=17',
          }, 
          {
            name: 'yy',
            url:'https://kbtest.hopto.org:3000/d/c2B-o05Gk/ml?orgId=1&from=now-5m&to=now&refresh=10s',
            size:"col-md-12",
            height:"800",

          },


          
        ],
      },
    },
    activePanel: 0,
  };
  render() {
    return (
      <div>
        <Nav name={this.state.panels[this.props.match.params.type].name}></Nav>
        <div className='row p-4'>
          {this.state.panels[this.props.match.params.type].graphs.map(
            (d, i) => (
              <div className={d.size?d.size:'col-md-6 my-3'} key={i}>
                <div className='card bg-white p-3'>
                  <iframe
                    title='123'
                    src={d.url + '&theme=light'}
                    className='w-100'
                    height={d.height?d.height==-1?"auto":d.height:"400"}
                    frameBorder={0}
                  ></iframe>
                </div>
              </div>
            )
          )}
        </div>
      </div>
    );
  }
}

export default Dashboards;
