import React, { Component } from 'react';
import Table from '../../components/Table';
import { Link } from 'react-router-dom';
import Nav from '../../components/Nav';
import Modal from '../../components/modal';
import Form from '../../components/form';
import { CheckCircle, XCircle } from 'react-feather';
import moment from 'moment';

class C2BResults extends Component {
  state = {
    tableData: { data: [] },
    response: { data: [] },
    tableError: false,
    query: {},
    filter: {},
    table_loading: false,
  };
  timeout = null;


 
  render() {

      return (
      <div className=''>
        <Nav
    name='AirtelC2B'
    buttons={[
        // {
        //     text: 'C2B Stk',
        //     onClick: () => {
        //         this.setState({modalVisible: true});
        //     },
        // },
    ]}/>
        <div className='mt-3 table-card border-0 card shado mx-3 shadow'>
          <div className='p-4'>
            <Table
              search={['PhoneNumber','AirtelReceiptNumber', 'EXTTRID',  'ResultCode', 'ResultDesc']}
              // sort="created_at"
              // sortDirection={-1}
              data={this.state.tableData}
              fetch={(params) => {
                this.setState({ query: params });
                setTimeout(() => {
                  this.fetch();
                }, 0);
              }}
              loading={this.state.table_loading}
              fetchError={this.state.tableError}
            />
          </div>
        </div>

        <Modal visible={this.state.modalVisible}>
          <h5 className='font-weight-bold'>Create IP Address</h5>

          <hr />

          <div className=''>
            <Form
              inputs={[
                // {
                //   label: 'IP Address',
                //   name: 'ip_address',
                //   type: 'text',
                // },
                // {
                //   label: 'Country',
                //   name: 'country',
                //   type: 'text',
                // },
                // {
                //   label: 'state',
                //   name: 'state',
                //   type: 'select',
                //   options: [
                //     {
                //       name: 'Whitelisted',
                //       value: 'WHITELISTED',
                //     },
                //     {
                //       name: 'Blacklisted',
                //       value: 'BLACKLISTED',
                //     },
                //   ],
                // },
                // {
                //   label: 'Description',
                //   name: 'description',
                //   type: 'textarea',
                // },
              ]}
              submit={(data) => {
                this.setState({ modalVisible: false });
                // this.createIP(data);
              }}
            />
          </div>
        </Modal>
      </div>
    );
  }

  fetch = () => {
    this.setState({ table_loading: true });

    window.app
      .service('airtel/c2b_results')
      .find({
        query: {
          ...this.state.query,
          AirtelReceiptNumber: this.props.paymentRequest?.ack_payment_reference,
        },
      })
      .then((response) => {
          console.log(response)
        response.data = response.data.map((d) => {
          return {

            id: d.id,
              PaymentRequest: (
                  <Link
                      className='link'
                      to={`/management/payment_requests/${d.AirtelReceiptNumber}/details`}>
                      {d.AirtelReceiptNumber}
                  </Link>
              ),
              PhoneNumber: d.PhoneNumber,
              Amount: d.Amount,
              EXTTRID: d.EXTTRID,
              ResultCode: d.ResultCode,
              ResultDesc: d.ResultDesc,
              TransactionDate: d.TransactionDate,
            date_created: moment(d.created_at).format('YYYY-MM-DD hh:mm:ss'),
          };
        });

        this.setState({ tableData: response, table_loading: false });
      })
      .catch((err) => {
        this.setState({ table_loading: false });
        console.error(err);
      });
  };
}

export default C2BResults;
